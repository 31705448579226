/*body {
  background: #f7f7f7;
  color: #204071;
}
header .rounded-jetsmart {
  border-radius: 0.5rem !important;
}
header .dropdown-toggle::after {
  transition: border 250ms ease-in-out;
}
header .dropdown-toggle.show::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}

header {
  transition: top 0.2s ease-in-out;
}
header.header * {
  font-family: "Lato", sans-serif;
}
header.header {
  background-color: #fff;
  padding: 0;
  font-family: "Lato", sans-serif;
  border-bottom: 4px solid #a62733;
}
header.header.visibility-changed {
  border-bottom: 4px solid #a62733;
}
header .navbar {
  padding: 0;
}
header .logo-jetsmart {
  width: 180px;
  transition: width 0.2s ease-in-out;
}
header.visibility-changed .logo-jetsmart {
  width: 150px;
}
header .navbar-brand {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 0px;
}
header .navbar-toggler {
  text-align: center;
  border: 0;
  border-radius: 0;
}
header .navbar-toggler-icon {
  height: 1.6em;
  width: 2em;
}

header .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

header .navbar-toggler .icon-bar {
  width: 40px;
  border-radius: 5px;
  background: #a62733;
  height: 4px;
  display: block;
}

header .navbar-toggler:hover .icon-bar {
  background: #a62733;
}
header .navbar-toggler > span > small {
  font-size: 10px;
  color: #204071;
  display: block;
  margin-top: 2px;
}
header .navbar-toggler:focus {
  outline: 0px;
}
header.header .navbar-nav {
  height: 86px;
  transition: height 0.2s ease-in-out;
}
header.header.visibility-changed .navbar-nav {
  height: 55px;
}
header.header .navbar-nav .nav-item {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  transition: background 250ms ease-in-out;
}
header.header .navbar-nav .nav-item > a {
  font-size: 1.1rem;
  color: #204071;
  font-weight: 500;
  padding: 0px;
  display: flex;
  height: 100%;
  width: 100%;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  transition: all 250ms ease-in-out;
}
header.header.visibility-changed .navbar-nav .nav-item > a {
  font-size: 1rem;
}
header.header .navbar-nav .nav-item > a > span {
  vertical-align: middle;
}
header.header .navbar-nav .nav-item:hover,
.header .navbar-nav .nav-item.show {
  background-color: #204071;
}
header.header .navbar-nav .nav-item:hover > a,
.header .navbar-nav .nav-item.show > a {
  color: #fff;
}
header.header .navbar-nav .nav-item > a .hover-border-bottom-center {
  display: inline-block;
}
header.header .navbar-nav .nav-item > a .hover-border-bottom-center:after {
  display: block;
  content: "";
  border-bottom: solid 2px #fff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
header.header .navbar-nav .nav-item:hover > a .hover-border-bottom-center:after,
header.header .navbar-nav .nav-item.show > a .hover-border-bottom-center:after {
  transform: scaleX(1);
}
header.header .navbar-nav .nav-item .dropdown-toggle.no-arrow::after {
  display: none;
}
header.header .navbar-nav .nav-item > a.active .hover-border-bottom-center,
.header .navbar-nav .nav-item > a:hover .hover-border-bottom-center {
}
header.header .navbar-nav .nav-item .dropdown-menu {
  left: initial;
  right: 0;
  float: right;
  min-width: 20rem;
  color: #fff;
  margin: 0;
  background-color: #204071;
  border: 0;
  border-radius: 0;
}
header.header .navbar-nav .nav-item .dropdown-item {
  color: #fff;
  font-size: 1.1rem;
}
header.header.visibility-changed .navbar-nav .nav-item .dropdown-item {
  font-size: 1rem;
}
header.header .navbar-nav .nav-item .dropdown-item:focus,
header.header .navbar-nav .nav-item .dropdown-item:hover {
  background: none;
}
header.header .navbar-nav .nav-item a.dropdown-item .hover-border-bottom-left {
  display: inline-block;
}
header.header
  .navbar-nav
  .nav-item
  a.dropdown-item
  .hover-border-bottom-left:after {
  display: block;
  content: "";
  border-bottom: solid 1px #e9b22a;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 0% 50%;
}
header.header
  .navbar-nav
  .nav-item
  a.dropdown-item:hover
  .hover-border-bottom-left:after {
  transform: scaleX(1);
}
header.header .navbar-nav .nav-item > a i {
  font-size: 1.5rem;
  margin: 0px 10px 0px 0;
  display: inline-block;
  vertical-align: top;
}


header.header .navbar-nav .nav-item.nav-checkin:hover,
.header .navbar-nav .nav-item.nav-checkin.show {
  background: none;
}
header.header .navbar-nav .nav-item.nav-checkin > a {
  background: #19aacb;
  padding: 15px 0;
  width: 150px;
  display: block;
  height: initial;
  color: #fff;
}
header.header .navbar-nav .nav-item.nav-checkin > a:hover,
header.header .navbar-nav .nav-item.nav-checkin > a:focus {
  background: #204071;
  color: #fff;
}

header.header .navbar-nav .dropdown-item .flag {
  vertical-align: top;
  margin-top: 0px;
}
header .border-white-2 {
  border: 2px solid white;
  transition: all 250ms ease-in-out;
}
header .border-blue-2 {
  border: 2px solid #204071;
  transition: all 250ms ease-in-out;
}
header.header .navbar-nav .nav-item:hover > a .border-blue-2,
.header .navbar-nav .nav-item.show > a .border-blue-2 {
  border: 2px solid white;
}
header.header .navbar-nav .nav-item.nav-countries .dropdown-menu {
   top: 105%;
	right: 0;
    left: initial;
	background: rgba(32, 64, 113, 0.95);
	border: 0;
	margin:0;

}
header.header
  .navbar-nav
  .nav-item.nav-countries
  .dropdown-menu
  .dropdown-item {
  /*color:white;
	font-size: 12px;
	padding: 5px 5px;

}
header.header
  .navbar-nav
  .nav-item.nav-countries
  .dropdown-menu
  .dropdown-item:focus,
header.header
  .navbar-nav
  .nav-item.nav-countries
  .dropdown-menu
  .dropdown-item:hover {
  background: transparent;
}

header.header .navbar-nav.mobile-navbar-countries .nav-countries {
  /*min-height: initial;
  margin-bottom: 0;
}
header.header.visibility-changed .navbar-nav.mobile-navbar-countries {
  height: initial;
}
header.header .navbar-nav.mobile-navbar-countries .nav-item.dropdown > a {
  /*min-height: initial;
}
header.header
  .navbar-nav.mobile-navbar-countries
  .nav-item.nav-countries
  .dropdown-menu {
  float: none;
  position: absolute;
  background-color: rgb(32, 64, 113);
  min-width: 250px;
}
@media (max-width: 1350px) and (min-width: 1201px) {
  header.header.visibility-changed .navbar-nav .nav-item > a {
    font-size: 0.82rem;
  }
}
@media (max-width: 1200px) and (min-width: 992px) {
  header.visibility-changed .navbar-brand {
    padding-left: 0px;
    padding-right: 5px;
  }
  header .logo-jetsmart {
    width: 140px;
  }
  header.visibility-changed .logo-jetsmart {
    width: 120px;
  }
  header.header .navbar-nav .nav-item > a {
    font-size: 0.86rem;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  header.header.visibility-changed .navbar-nav .nav-item > a {
    font-size: 0.82rem;
  }
  header.header .navbar-nav .nav-item .dropdown-menu h5 {
    font-size: 1.1rem;
  }
  header.header.visibility-changed .navbar-nav .nav-item .dropdown-menu h5 {
    font-size: 1rem;
  }
  header.header .navbar-nav .nav-item .dropdown-item {
    font-size: 0.86rem;
  }
  header.header.visibility-changed .navbar-nav .nav-item .dropdown-item {
    font-size: 0.8rem;
  }
}
@media (max-width: 1175px) and (min-width: 992px) {
  /*header.header.visibility-changed .navbar-nav .nav-item > a {
		font-size: .68rem;
	}
  header.header.visibility-changed .navbar-nav .nav-item {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media (max-width: 991px) {
  header.header {
    padding: 0px 0;
  }
  header.header .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
  }
  header .logo-jetsmart {
    margin: 10px auto;
  }
  header.header .navbar-nav .nav-item.nav-countries .dropdown-menu {
    background: transparent;
  }
  header.header .navbar-nav,
  header.header.visibility-changed .navbar-nav {
    height: auto;
    display: block;
  }
  header.header .navbar-nav .nav-item {
    min-height: 56px;
    margin-bottom: 5px;
    width: 100%;
  }
  /*header.header .navbar-nav .nav-item.dropdown{
		display: block !important;
	}
  header.header .navbar-nav .nav-item.dropdown.d-none {
    display: none !important;
  }
  header.header .navbar-nav .nav-item.dropdown > a {
    min-height: 56px;
  }
  header.header .navbar-nav .nav-item .dropdown-menu {
    float: initial;
  }
  header.header .navbar-nav .nav-item.nav-checkin > a {
    width: 100%;
    background: none;
    padding: 0;
    height: 100%;
    display: flex;
    color: #204071;
  }
  header.header .navbar-nav .nav-item.nav-checkin:hover > a,
  .header .navbar-nav .nav-item.nav-checkin.show > a {
    color: #fff;
  }
  header.header .navbar-nav .nav-item.nav-checkin > a:hover,
  header.header .navbar-nav .nav-item.nav-checkin > a:focus {
    background: none;
    color: #fff;
  }
  header.header .navbar-nav .nav-item.nav-checkin:hover,
  .header .navbar-nav .nav-item.nav-checkin.show {
    background-color: #204071;
  }
}
@media (max-width: 768px) {
  header .navbar-collapse.show {
    overflow: overlay;
    max-height: 76vh;
    margin-left: -15px;
    margin-right: -15px;
  }
  header.header .navbar-nav .nav-item .dropdown-menu {
    min-width: initial;
    width: 100%;
  }
  header.header .navbar-nav .nav-item .dropdown-menu h5 {
    font-size: 1.1rem;
  }
  header.header .navbar-nav .nav-item > a {
    font-size: 0.89rem;
  }
  header.header .navbar-nav .nav-item .dropdown-item {
    font-size: 0.95rem;
  }
}
@media (max-width: 480px) {
  header.header .container {
    padding-right: 5px;
    padding-left: 5px;
  }
  .navbar-toggler {
    padding: 0.25rem 0.25rem;
  }
  header .logo-jetsmart {
    width: 160px;
  }
  header.header .navbar-nav.mobile-navbar-countries .nav-countries {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
@media (max-width: 400px) {
  header .navbar-brand {
    padding-left: 0px;
    padding-right: 0px;
  }
  header .logo-jetsmart,
  header.visibility-changed .logo-jetsmart {
    width: 130px;
  }
}
@media (max-width: 350px) {
  header.header .navbar-nav.mobile-navbar-countries .nav-countries {
    position: initial;
  }
  header.header
    .navbar-nav.mobile-navbar-countries
    .nav-item.nav-countries
    .dropdown-menu {
    top: 56px;
  }
  header .logo-jetsmart,
  header.visibility-changed .logo-jetsmart {
    width: 110px;
  }
}
@media screen and (min-width: 576px) {
  header .has-mega-menu .container-sm {
    width: 240px;
  }
}

@media screen and (min-width: 768px) {
  header .has-mega-menu .container-md {
    width: 300px;
  }
}

@media screen and (min-width: 992px) {
  header .has-mega-menu .container-lg {
    width: 420px;
  }
}

@media screen and (min-width: 1200px) {
  header .has-mega-menu .container-xl {
    width: 600px;
  }
}
*/

/* Countries */
header .rounded-pin {
  border-radius: 50% 50% 0 50% !important;
}
header.header .navbar-nav .flag {
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 5px;
  line-height: 0;
  margin-top: -2px;
}
