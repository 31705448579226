.svg-container {
  width: 50px; /* Set the desired width for the displayed square */
  height: 50px; /* Set the desired height for the displayed square */
  overflow: hidden; /* Hide any content outside the defined dimensions */
}

.cropped-square {
  width: 22px; /* Set the width of the cropped SVG image */
  height: 22px; /* Set the height of the cropped SVG image */
  background-image: url("../images/sprite-banderas.svg");
  background-size: 576px 331px; /* Position the square within the background */
}
